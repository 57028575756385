$c-primary: #000000;
$c-secandary: #007f00;
$dots: false;
$loading-bg-overrided: white;
$progress-bar-overided: #007f00;
$base-font-family: 'Univers', sans-serif;
$font-family-heading: 'Univers', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  position: relative;
  width: 100%;

  .icon {
    position: absolute;
    right: 12px;
    top: 50%;
    margin-top: -12px;
    border: none;
    background: none;
    display: block;
  }

  .transparentButton {
    border: none;
    background: none;
    display: block;
    width: 100%;
  }
}
