$c-primary: #000000;
$c-secandary: #007f00;
$dots: false;
$loading-bg-overrided: white;
$progress-bar-overided: #007f00;
$base-font-family: 'Univers', sans-serif;
$font-family-heading: 'Univers', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: stretch;
  font-size: 1rem;
  line-height: 1.25;
  font-family: var(--base-font-family);
  &.fill_view_port {
    height: 100%;
  }

  .description {
    text-align: left;
  }

  .option_list {
    margin-top: 1.5rem; //24
    margin-bottom: 1.5rem; //24

    .option_item {
      border: 1px solid #ccc;
      border-radius: 10px;
    }
  }

  .animation-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
    flex: 1;
    overflow: hidden;
    background-color: var(--page-background-color);
  }
}
