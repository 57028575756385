$c-primary: #000000;
$c-secandary: #007f00;
$dots: false;
$loading-bg-overrided: white;
$progress-bar-overided: #007f00;
$base-font-family: 'Univers', sans-serif;
$font-family-heading: 'Univers', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.checkbox {
  display: none;
}

.option_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  height: 2.8125rem; //45
  margin-bottom: 0.6875rem; //11
  cursor: pointer;
  &.checked {
    background: #f4f4f4;
    border-radius: 10px;
  }

  .check_mark_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 3.3125rem; //53
    height: 2.8125rem; //45

    img {
      width: 1.375rem; //22
      height: auto;
    }
  }

  .option_title {
    flex: 1;
  }
}
