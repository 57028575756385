$c-primary: #000000;
$c-secandary: #007f00;
$dots: false;
$loading-bg-overrided: white;
$progress-bar-overided: #007f00;
$base-font-family: 'Univers', sans-serif;
$font-family-heading: 'Univers', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.content {
  word-break: break-word;

  a {
    font-style: italic;
  }
}
