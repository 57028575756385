$c-primary: #000000;
$c-secandary: #007f00;
$dots: false;
$loading-bg-overrided: white;
$progress-bar-overided: #007f00;
$base-font-family: 'Univers', sans-serif;
$font-family-heading: 'Univers', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.quickAddButtonGroup {
    width: 100%;
    margin-bottom: 1rem;
    display: flex;

    .quickAddButton {
        margin-right: 0.5rem;
    }
}